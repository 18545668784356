.container{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    justify-content: center;
    background-image: url('./../../assets/backGround.jpg');
  background-size: cover; /* Cover the entire background */
  background-position: center; /* Center the background image */
  color:white;
  font-family: 'RubikGemstones';
}

.btns{
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    margin-left: 5px;
    margin-right: 5px;
}
.img{
    object-fit: contain;
}
.title{
    font-size: 40px;
    margin-bottom: 30px;
}

