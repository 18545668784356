@font-face {
    font-family: 'RubikGemstones';
    src: url('./fonts/RubikGemstones-Regular.ttf') format('truetype');
  }


body{
    height: 100vh;
}
#root{
    display: flex;
    height: 100vh;
    flex-direction: column;
}